import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function contactForm() {
  return (
    <Form style={{
        display:"inline-block",
        width:"20rem",
        backgroundColor: "floralwhite",
        padding: "1rem",
        borderRadius: ".375rem",
        position:"relative"
        
        
    }}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Your Email:</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Message</Form.Label>
        <Form.Control type="Message" placeholder="Enter Message" />
      </Form.Group>
      <Form.Text>Backend is in the works. Use feel free to contact me using the email provided in my resume. Thanks.</Form.Text>
      <br/>
      <br/>
      <Button variant="primary" type="submit" style={{float:"right"}}>
        Send
      </Button>
    </Form>
  );
}
