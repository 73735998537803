import * as React from "react";
import {Navblock} from "./Nav";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Rooms from "./Rooms.js";




export default function Projects() {
    return(
        <div id="projects">
            <Navblock/>


            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="react" title="React">
                    <div><Rooms/></div>

                </Tab>
                <Tab eventKey="webscraping" title="Web Scraping">
                
                </Tab>
                <Tab eventKey="components" title="Components">
                
                </Tab>
            </Tabs>
        </div>
    );
}