import React from "react";
import Table from 'react-bootstrap/Table';

export default function Skilltable() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Skill</th>
          <th>Proficiency</th>
          <th>Experience</th>
          <th>IDE</th>
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>React</td>
          <td>Intermediate</td>
          <td>Self-Taught</td>
          <td>VScode</td>
          <td>Web</td>
          
        </tr>
        <tr>
          <td>React Native</td>
          <td>Beginner</td>
          <td>Self-Taught</td> 
          <td>Vscode</td> 
          <td>Mobile</td>
        </tr>
        <tr>
          <td>Javascript</td>
          <td>Intermediate</td>
          <td>Self-Taught</td>
          <td>VScode</td>
          <td>Web</td>
        </tr>
        <tr>
          <td>HTML</td>
          <td>Intermediate</td>
          <td>Profesional/Self-Taught</td>
          <td>VScode/CMS</td>
          <td>Web</td>
        </tr>
        <tr>
          <td>CSS</td>
          <td>Intermediate</td>
          <td>Profesional/Self-Taught</td>
          <td>VScode/CMS</td>
          <td>Web</td>
        </tr>
        <tr>
          <td>Python</td>
          <td>Intermediate</td>
          <td>University</td>
          <td>VScode</td>
          <td>Web Scraping</td>
        </tr>
        <tr>
          <td>Selenium</td>
          <td>Intermediate</td>
          <td>Self-Taught</td>
          <td>VScode</td>
          <td>Web Scraping</td>
        </tr>
        <tr>
          <td>BeautifulSoup4</td>
          <td>Intermediate</td>
          <td>Self-Taught</td>
          <td>VScode</td>
          <td>Web Scraping</td>
        </tr>
        <tr>
          <td>Java</td>
          <td>Intermediate</td>
          <td>University</td>
          <td>Eclipse</td>
          <td>General Coding</td>
        </tr>
        <tr>
          <td>C#</td>
          <td>Beginner</td>
          <td>Self-Taught</td>
          <td>Unity</td>
          <td>Game Dev</td>
        </tr>
      </tbody>
    </Table>
  );
}
