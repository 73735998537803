import * as React from "react";
import { Routes, Route} from "react-router-dom";
import About from "./About.js";
import Projects from "./Projects";
import Skills from "./Skills.js";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import Formdrop from "../components/formDrop.js";
import mePic from "../assets/mePic.jpeg";
export function NavRoutes() {
    return(
        
            <Routes>
                <Route path="/" element={<About/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/skills" element={<Skills/>} />
                <Route path="/projects" element={<Projects/>} />
            </Routes>
    );
}

export function Navblock() {
    return(
        <Navbar bg="dark" variant="dark" sticky="top">
            <Container>
                <Navbar.Brand><img src={mePic} alt="" width="30" height="30" style={{borderRadius:"30px", marginRight:"1.5rem"}}/>Jeremiah Garcia</Navbar.Brand>
                <Nav defaultActiveKey="/about" className="me-auto">
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/skills">Skills</Nav.Link>
                    <Nav.Link href="/projects">Projects</Nav.Link>
                    <Formdrop/>
                </Nav>
            
            </Container>
        </Navbar>
        
    );
}
