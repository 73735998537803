import * as React from "react";
import {Navblock} from "./Nav";
import Skilltable from "../components/Skilltable.js";

export default function Skills() {
    return(
        <div id= "skills" >
            <Navblock/>
            <div><Skilltable/></div>
            
        </div>
    );

}