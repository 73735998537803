import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import Contactform from "./contactForm.js";
import Button from '@mui/material/Button';



export default function SimpleCollapse() {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div>  
        <Button variant="contained" checked={checked} onClick={handleChange}>Contact Me</Button>
        <Collapse style={{position:"absolute",}}in={checked}><Contactform/></Collapse>  
    </div>
  );
}
