import * as React from "react";
import {Navblock} from "./Nav";
import Bodycards from "../components/Bodycards.js"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import ContactForm from '../components/contactForm.js';

export default function About() {

    var socialArray = [ 
        {"title":"LinkedIn", "link":"https://www.linkedin.com/in/jeremiah-garcia-b35552219", "icon":<LinkedInIcon/>, 
        "txt":"Includes certifications from IBM SkillsBuild(Cybersecurity) and Coursera(React). "},


        {"title":"Github", "link":"https://github.com/Jercia3", "icon":<GitHubIcon/>,
        "txt":"There are a few web scraping projects. Working on adding additional projects."}
    ]

    return(
        <div id="about" style={{height:"100%", background:"linear-gradient(lightskyblue, ghostwhite)"}}>
            <Navblock/>
            <Row xs={1} md = {1} style={{width:"40%", display:"inline-block", float:"left"}} >
                {socialArray.map((_, idx)=> (
                <Col>
                        <Bodycards soc = {socialArray[idx]}/>           
                </Col>
                ))}
            </Row>
            <div style={
                {
                display: "inline-block",
                marginTop:"3rem",
                height:"45rem",
                width:"45rem",
                borderRadius:"0.375rem"
            }}>
            <iframe
            title= "title"
            style={{
            position: "relative",
            
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "0.375rem"
            }}
            src={`https://docs.google.com/document/d/1N_zqgOknz6rL0jOHJKlymFpsvvfXMV7I/preview`}
            frameBorder="0"
            />
            </div>

            

        </div>
        
    );

}
