import * as React from "react";
import Table from "react-bootstrap/Table";



const header = ["hone", "htwo", "hthree", "hfour"]

export default function Rooms() {
    return(
        <div id="rooms">
            <Table striped bordered hover>
                <thead>
                <tr>
                {header.map((_, idx)=> (
                <th>
                    {header[idx]}
                </th>
                ))}
                </tr>
                </thead>

                <tbody>
                            
                
                </tbody>
            </Table>
        </div>
    );
}