import React from "react";
import Card from "react-bootstrap/Card";

export default function Bodycards(props) {
    return(
    <Card style={{ width: '20rem', height: '12rem', margin:"3rem" }}>
      <Card.Header>{props.soc.icon}{props.soc.title}</Card.Header>
      <Card.Body>
        <Card.Text>
          <a href={props.soc.link}>{props.soc.title}</a>
        </Card.Text>
        <Card.Text>
          <p style={{borderStyle:"solid", borderColor:"lightgrey", borderRadius:"0.375rem", position:"relative", height:"5rem", padding:"3px", backgroundColor:"FFFFF0"}}>{props.soc.txt}</p>
        </Card.Text>
        <Card.Img />
      </Card.Body>
    </Card>
    );

}
